body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-custom {
  background-color: #2764a0;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.btn-custom i {
  margin-right: 5px;
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.tag {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  color: #333;
  display: inline-block;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .tags-container {
    justify-content: center;
  }
}

.carousel-container {
  width: 80%;
  margin: 0 auto;
}

.carousel-container img {
  width: 100%;
  height: auto;
}

.info-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.info-item {
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-item i {
  font-size: 1.2rem;
}

.profile-section {
  margin-left: auto;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-info h4 {
  margin: 0;
  font-size: 1rem;
}

.profile-info button {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
}

.profile-chat-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1rem;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 50%;
}

.profile-chat-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-chat-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-chat-info h4 {
  margin: 0;
  font-size: 1.5rem;
}

.chat-icon {
  font-size: 2rem;
  color: #2e2e2e;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}


/* MODALES */

/* Fondo del modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

/* Contenido del modal */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80% !important;
  /*max-width: 1800px;*/
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* Encabezado del modal */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 10px;
}

/* Botón de cerrar */
.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Cuerpo del modal */
.modal-body {
  padding: 0;
}

/* Pie del modal */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e9ecef;
  padding-top: 10px;
}

.modal-actions {
  justify-content: center;
  display: flex;
  gap: 20px;
  margin-top: 5%;
}



/* ALERTAS */
.alert-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
}