.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    height: 60px; 
}

.navbar {
    padding: 0.5rem 1rem;
}

.bg-darkblue {
    background-color: #2f3030; 
}

.dropdown-menu {
    min-width: 10rem;
}

.dropdown-item {
    cursor: pointer;
}

.main-content {
    margin-top: 60px;
}
